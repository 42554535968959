<template>
  <div>
    <div class="flex_sp">
      <div class="str flex_c_c">为您推荐</div>
      <div class="flex_row">
        <div class="circle" @click="pre"><img src="@/assets/img/goods/pre.png" /></div>
        <div class="circle" @click="next"><img src="@/assets/img/goods/next.png" /></div>
      </div>
    </div>
    <div class="rec_main">
      <div v-for="item in list" :key="item.id" class="flex_row_aic item">
       
        <!--  -->
        <div style="width: 87px; height: 87px"  class="sku" >
          <img style="width: 87px; height: 87px" :src="item.image.url" alt="" />
          <div class="skuitem" v-if="item.kc_str == '无库存'">
            <img src="@/assets/img/goods/kcbz.png"  alt="" />
          </div>
        </div>
        <div class="info c6">
          <p class="shenglue_1 c3">{{ item.title }}</p>
          <p class="shenglue_1 f12">{{ item.specification }}</p>
          <p class="shenglue_1 f12">{{ item.manufacturer }}</p>
          <p class="price">{{ item.price }}</p>
          <div class="add flex_c_c" @click="handleAdd(item)">加入购物车</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      page_size: 8,
      total: 0,
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$api("goods.getGoods", { discover_type: 101, page: this.page, page_size: this.page_size }).then((res) => {
        this.list = res.data;
        this.total = Math.ceil(res.listTotal / this.page_size);
      });
    },
    next() {
      if (this.page >= this.total) return;
      this.page = this.page + 1;
      this.get();
    },
    pre() {
      if (this.page <= 1) return;
      this.page = this.page - 1;
      this.get();
    },
    handleAdd(item) {
      if (item.can_buy == 102) {
        this.$message.error(item.can_buy_str);
        return;
      }
      let p = {
        id: item.id,
        number: item.cart_number,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("加入购物车成功");
        this.$store.dispatch("getBadge");
        this.$parent.getCartList()
      });
    },
  },
};
</script>

<style lang="less" scoped>
.str {
  width: 90px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  font-weight: bold;
  color: #333333;
  border-bottom: 2px solid #fff;
}
.circle {
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:first-child {
    margin-right: 14px;
  }
}
.rec_main {
  margin-top: -2px;
  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 12px 0 12px;
  .item {
    width: 24%;
    margin-right: 12px;
    margin-bottom: 30px;
    &:nth-child(4n + 4) {
      margin-right: 0;
    }
    .sku {
      position:relative;
      text-align:center;
      .skuitem{
        position:absolute;
        top:8px;
        left:8px;
        width:70px;
        height:70px

      }
    }
  }
  .info {
    text-align: left;
    margin-left: 10px;
    line-height: 1.5;
    .c3 {
      font-weight: bold;
    }
    .price {
      font-weight: bold;
      color: @priceRed;
    }
    .add {
      width: 70px;
      height: 23px;
      border: 1px solid #bbbbbb;
      border-radius: 2px;
      font-size: 12px;
      color: #333333;
      cursor: pointer;
    }
  }
}
</style>
